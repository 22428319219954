var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ssr-carousel',_vm._g(_vm._b({ref:"ssrCarousel",class:_vm.$bem('carousel'),attrs:{"show-dots":false},scopedSlots:_vm._u([{key:"back-arrow",fn:function(ref){
var disabled = ref.disabled;
return [_c('Hover',{model:{value:(_vm.isHovering),callback:function ($$v) {_vm.isHovering=$$v},expression:"isHovering"}},[_c('div',{class:[_vm.$bem('__arrow'), _vm.arrowsBgHalfRoundedClass('left')]},[_c('v-icon',{staticClass:"mdi mdi-chevron-left base--text",class:_vm.$bem('__arrow-icon'),attrs:{"color":"black"}})],1)])]}},{key:"next-arrow",fn:function(ref){
var disabled = ref.disabled;
return [_c('Hover',{model:{value:(_vm.isHovering),callback:function ($$v) {_vm.isHovering=$$v},expression:"isHovering"}},[_c('div',{class:[_vm.$bem('__arrow'), _vm.arrowsBgHalfRoundedClass('right')]},[_c('v-icon',{staticClass:"mdi mdi-chevron-right base--text",class:_vm.$bem('__arrow-icon'),attrs:{"color":"black"}})],1)])]}}]),model:{value:(_vm.iValue),callback:function ($$v) {_vm.iValue=$$v},expression:"iValue"}},'ssr-carousel',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2),(_vm.showDots && _vm.itemsLength > 1)?_c('div',{staticClass:"w100 d-flex justify-center"},[_c('div',{staticClass:"d-flex flex-wrap rounded-lg justify-center py-1 px-2",class:[
				_vm.$bem('__dots'),
				{ 'pa-2': _vm.$b.m },
				{ 'carousel__dots--desktop': _vm.$b.td, 'w100 mx-2': _vm.dotContainerFullWidthMobile && _vm.$b.m } ]},_vm._l((_vm.itemsLength),function(item){return _c('span',{key:item,class:[_vm.$bem('__dots-icon'), _vm.activeDot(item), { 'carousel__dots-icon--mobile': _vm.$b.m }]})}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }